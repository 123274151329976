@import "./mixins";

// Colors
@import "./colors";
@import "./table";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=HK+Grotesk:wght@500;600&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Font+Awesome+5+Brands:wght@400&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Abel&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300;1,400&display=swap");
body {
  margin: 0;
  line-height: normal;
}

.other-header{
  background: #222222;
}

.headerSearch {
  position: relative;
  font-size: 14px;
  color: #000;
 

  .Searchbar {
    background: #F9F9F9;
    height: 34px;
    font-size: 14px;
    padding: 6px 54px 6px 12px;
    font-weight: 400;
    font-size: 14px;
    font-weight: 400;
  }

  >i {
    position: absolute;
    top: 50%;
    color: #84818A;
    transform: translateY(-50%);
    cursor: pointer;

    &.fa-search {
      // left: 10px;
      right: 10px;
    }

    &.fa-times {
      right: 30px;
    }
  }
}

.headerSearch .remove-icon {
  position: absolute;
  right: 36px !important;
  top: 17px;
}


.cursor-pointer{
  cursor: pointer;
}

img.delete_icon {
  width: 80px;
}
img.user_img {
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.pointer {
  cursor: pointer;
}
.product_details {
  line-height: 21px;
  margin-left: 1rem;
}
.Sales_row {
  background: #fff;
}
.Sales_row p {
  color: #111827;
  font-size: 16px;
  margin-bottom: 10px;
}
.Sales_row h2 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-size: 20px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Sales_row p {
  color: #111827;
  font-size: 16px;
  margin-bottom: 10px;
}
.green_icon {
  background: #04785714;
  color: #047857 !important;
}
span.green_icon span.material-icons.mr-1 {
  line-height: 0 !important;
  position: relative;
  top: 5px;
}
.green_icon,
.red_icon {
  border-radius: 30px;
  font-size: 12px;
  margin-right: 5px;
  padding: 5px;
}
span.text_color {
  color: #6b7280;
  font-size: 12px;
}
.Sales_box,
.Sales_row {
  border-radius: 12px;
  padding: 15px;
}
.tox-notifications-container {
  visibility: hidden;
}
.cat_drop_multi .searchWrapper {
  padding: 8px 23px !important;
}
.find_title {
  font-size: 20px;
}
.mainfind {
  border-radius: 12px;
  border: 1px solid #31a3d903;
}
li.shadow.p-4.pointer.border-rounded.font-weight-bold {
  border-radius: 20px;
  padding: 50px 10px !important;
  border: 2px solid #e6e6e6;
  font-weight: 200 !important;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.1) !important;
}
.btSmall {
  padding: 4px 20px !important;
  cursor: pointer;
}
ul.col-md-4 li {
  list-style: none;
}
li.shadow.p-4.pointer.font-weight-bold:hover {
  background: #44a1d9;
  color: #fff;
  transition: 0.5s;
}

span.rejected {
  background: #ff000014;
  padding: 5px 10px;
  border-radius: 20px;
  color: red;
  text-transform: capitalize;
}
input.enterEmail {
  background: transparent;
  box-shadow: none !important;
  border: none !important;
  width: 190px;
}
span.accepted {
  background: #2181001f;
  padding: 5px 10px;
  border-radius: 20px;
  color: green;
  text-transform: capitalize;
}
.close_icon {
  position: relative;
  top: 2px;
}
span.pending {
  background: #f9a71026;
  padding: 5px 10px;
  border-radius: 20px;
  color: orange;
  text-transform: capitalize;
}
/* another way */
.tox-notifications-container {
  display: none;
}
:root {
  /* fonts */
  --font-hk-grotesk: "Poppins", sans-serif;
  --font-font-awesome-5-brands: "Font Awesome 5 Brands";
  --font-poppins: "Poppins", sans-serif;

  /* font sizes */
  --font-size-lg: 18px;
  --font-size-base: 16px;
  --font-size-5xl: 18px;
  --font-size-13xl: 32px;
  --font-size-11xl: 30px;
  --font-size-37xl: 42px;
  --font-size-17xl: 36px;
  --font-size-xs: 12px;

  /* Colors */
  --color-gray-100: #252525;
  --color-gray-200: rgba(0, 0, 0, 0.59);
  --color-gray-300: rgba(0, 0, 0, 0.5);
  --color-gray-400: rgba(0, 0, 0, 0.3);
  --color-deepskyblue-100: #20a2dc;
  --text-on-dark: #adb7c2;
  --white: #fff;
  --blue: #008aff;
  --color-mediumseagreen: #63c17c;
  --color-darkgray-100: #9d9d9d;
  --dark: #222d39;
  --color-cornflowerblue: #1e88e5;

  /* Gaps */
  --gap-mini: 15px;
  --gap-8xs: 5px;
  --gap-56xl: 75px;
  --gap-11xl: 30px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-5xl: 24px;

  /* Border radiuses */
  --br-10xs: 3px;
  --br-3xs: 10px;
  --br-xl: 20px;
  --br-11xl: 30px;
}
.chip {
  align-items: center;
  background: #0096fb !important;
  border-radius: 11px;
  color: #fff;
  display: inline-flex;
  font-size: 12px !important;
  line-height: 19px;
  margin-right: 5px;
  padding: 2px 10px !important;
}

// .searchbox {
//   border-radius: 5px 0px 0px 7px !important;
// }
.css-13cymwt-control {
  border-radius: 50px !important;
}
.selectbox {
  border-radius: 50px !important;
}

.searchbtn {
  border-radius: 0px 5px 5px 0px !important;
}
span.material-icons.increase {
  font-size: 49px;
  color: #31a3d9;
}
.bg_blue_port {
  background: #5cc1f230;
  border: none;
}
img.img_person {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
  margin: auto;
}
p.bg_invite {
  background: #4a9edc2b;
  padding: 10px;
  border-radius: 5px;
}
body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}
.makeitdisable {
  cursor: not-allowed !important;
}

.btn-sm {
  padding: 5px 12px !important;
  font-size: 14px !important;
}
.btn-vsm {
  padding: 3px 11px !important;
  font-size: 10px !important;
}

// signup css
h3.text-left.lgtext {
  font-weight: 600;
  font-size: 24px;
  color: #444444;
}
.form_home {
  margin-top: 2rem;
  // background: #0066a914;
  padding: 2rem;
  border-radius: 10px;
  // box-shadow: 0px 0px 16px #c2bdbd;
}
.red {
  color: red;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  // position: relative;
  // background-color: initial;
  // border: none;
  // transition: inherit;
  border: 1px solid #dcdcdc;
}

.signup_tab {
  border: none !important;
}

.signuptab {
  border: none !important;
  background: #fff;
  border-radius: 3px;
  height: 39px;
}

.signup-tab_cls {
  background: #fff;
  margin-left: 12px;
  border-radius: 3px;
  border: none !important;
  height: 39px;
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  width: 352px;
  height: 32px;
  padding: 0 11px;
  background: initial !important;
}
p.profile_data p {
  margin-bottom: 0px;
}
.marginleft {
  width: 100%;
}
:where(.css-dev-only-do-not-override-190m0jy).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  width: 100%;
  height: 38px !important;
  padding: 0 11px;
}
.form_profile {
  margin-top: 2rem;
  // background: #0066a914;
  padding: 2rem;
  border-radius: 10px;
  // box-shadow: 0px 0px 16px #c2bdbd;
}
.output h6,
h5 {
  margin-bottom: 0px;
}
.output h6 {
  font-size: 13px;
}
.output {
  margin-bottom: 10px;
  border-bottom: 2px solid #cccccc2b;
  background: #4a9edc1a;
  padding: 10px;
}
a.sign_up:hover {
  color: #d9b451 !important;
}
a:hover {
  text-decoration: none;
}

img.logo_name {
  width: 100%;
  max-width: 74px;
  max-height: 74px;
  object-fit: contain;
  background-color: #ffffff;
  border-radius: 5px;
}
.logo_image {
  display: flex;
  justify-content: center;
  margin: 0rem 0rem 1rem 0rem;
}

.forget a {
  color: #fff;
  margin: 5px 0 25px 0;
  display: block;
}
input.form-control.mb-0.bginput.changes {
  background-color: #fff !important;
  color: #000 !important;
  border-color: #d2cece !important;
  border: 1px solid #d2cece !important;
  border-radius: 38px !important;
  height: 40px;
}

input.form-control.bginput.chnages,
select.form-control.bginput.chnages {
  background-color: #fff !important;
  color: #000 !important;
  border-color: #d2cece !important;
  border: 1px solid #d2cece !important;
  border-radius: 38px !important;
  height: 38px;
  font-size: 14px;
}

select.form-select.bginput.chnages {
  background-color: #fff !important;
  color: #000 !important;
  border-color: #d2cece !important;
  border: 1px solid #d2cece !important;
  border-radius: 38px !important;
  height: 40px;
}
.nav-tabs {
  border-bottom: 1px solid #ffffff;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #f9f9f9 !important;
  background-color: #00a5d9;
  border-color: #dee2e6 #dee2e6 #fff;
  border-radius: 4px;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #fff #fff #fff;
  color: #000;
}

form.job_search {
  width: 100%;
}

b.text-primary.font-weight-bold.text-capitalize.pointer {
  font-size: 16px;
  font-weight: 500 !important;
}

// end
.holidays {
  display: block;
  font-size: 14px;
  margin-bottom: 9px;
  position: relative;
  padding-left: 15px;
  cursor: pointer;

  input {
    margin-right: 10px;
  }

  &.active {
    color: $primaryColor;
  }

  > .bold {
    font-weight: 500;
  }

  // &::before{
  //     content: "";
  //     height: 5px;
  //     width: 5px;
  //     position: absolute;
  //     background-color: #000;
  //     left: 0;
  //     top:8px;
  //     border-radius: 50%;
  // }
}

a:disabled,
.disabled {
  pointer-events: none;
}

.paginationWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;

  > span {
    font-size: 12px !important;
    color: #84818a;
  }
}

.alignTopDate div.ant-picker-input {
  top: -12px;
}

.react-pagination-js-default {
  ul {
    display: flex;
    align-items: center;
    column-gap: 10px;

    > li {
      margin: 0 !important;
      font-size: 13px !important;
      padding: 7px 13px !important;

      &:first-child {
        padding: 0 !important;

        &:not(.disabled) {
          color: #1f7bf4 !important;
        }

        &::after {
          content: "< Previous";
        }

        a {
          display: none;
        }
      }

      &:last-child {
        padding: 0 !important;

        &:not(.disabled) {
          color: #7e1e2d !important;
        
        }

        &::after {
          content: "Next >";
        }

        a {
          display: none;
        }
      }

      &.page {
        border-color: #7e1e2d !important;
        background-color: transparent !important;
        box-shadow: none !important;
        font-weight: 400 !important;
      }
      &.disabled {
        border: none !important;
      }

      &.is-active {
        font-weight: 500 !important;
        background: rgb(111 33 46 / 9%) !important;
        border-color: #7e1e2d !important;
        box-shadow: none !important;
        text-shadow: none !important;
        color: #202020 !important;
      }
    }
  }
}
.react-pagination-js-default ul a{
  color: #6f212e !important;
}
.react-pagination-js-default ul > li:last-child {
  border: none !important;
}
body {
  font-family: $font1 !important;
}

a {
  // color: #00a5d9;
  color: #d9b451;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font2;
}
.form-control:focus {
  box-shadow: none !important;
}

.contract_box:hover {
  background: #eaf5fb;
  padding: 15px;
  border-radius: 10px;
}
.contract_box {
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  background: #e4eff54d;
}
input,
select,
.input-group-text {
  border-radius: 50px !important;
}
textarea.form-control {
  border-radius: 12px;
}
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-control,
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-select,
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
label {
  font-size: 14px;
  font-weight: 500;
  // text-transform: capitalize;
  color: #fff;
}
span.timer {
  padding: 10px;
  background: #31a3d92b;
  width: 50px;
  display: inline-block;
  text-align: center;
  border: 1px solid #cccccc6b;
  margin-left: 13px;
  border-radius: 6px;
}
.col_side div span {
  font-size: 15px;
  font-weight: 300;
  color: #202020;
}

i.fa {
  cursor: pointer;
}

.dateRangePicker {
  padding: 10px !important;
  width: 100% !important;
}

.react-tooltip {
  max-width: 280px;
  word-break: break-all;
  white-space: initial;
  background: #fff !important;
  color: #000 !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.optionContainer {
  border: none !important;
  margin-bottom: 4px;
}

.min-sidebar ~ div > .main-sidebar a.col_side:hover {
  justify-content: center;
  margin-right: 0px !important;
}

.multiSelectContainer li:hover {
  background-color: #31a3d92b !important;
  color: #31a3d9 !important;
  border-radius: 8px;
}

// .optionListContainer {
//   background: #fff;
//   margin-top: 1px;
//   position: absolute;
//   width: 100%;
//   z-index: 2;
//   border: none;
//   border-radius: 14px;
//   padding: 0.7rem 0.5rem;
//   box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
// }

.optionListContainer {
  background: #fff;
  margin-top: 1px;
  position: absolute;
  width: 199px !important;
  z-index: 99999999999999 !important;
  border: none;
  border-radius: 14px;
  padding: 0.7rem 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.Postscls {
  border-radius: 8px !important;
}
.selectbox {
  border-radius: 50px !important;
  color: #808080;
  font-size: 15px;
  width: 150px;
}
.star {
  color: red;
  // display: none;
  // color: #202020;
}

.action_icons {
  display: flex;
}
.action_icons i.fa.fa-eye {
  color: #898989;
}
a.edit_icon {
  border: 1px solid #dcdcdc;
  height: 30px;
  width: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin-right: 6px;

  &:hover {
    text-decoration: none !important;
  }
}

.mainarea {
  background: #fff !important;
  padding: 30px;
  height: calc(100vh - 160px) !important;
  overflow: auto;
  border-radius: 9px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border: 1px solid #00000017;
}

span.edit_icon {
  border: 1px solid #dcdcdc;
  height: 30px;
  width: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  margin-right: 6px;
  cursor: pointer;
}

i.material-icons.edit {
  font-size: 15px;
  font-weight: 600;
  color: #898989;
}

i.material-icons.delete {
  font-size: 15px;
  font-weight: 600;
  color: #898989;
}

i.fa.fa-arrow-left {
  color: #000;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9 !important;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 1px solid #fff !important;
  // text-transform: capitalize;
  white-space: nowrap;
}

i.fa.fa-eye.eye {
  color: #3574ac;
}
.closeicon {
  position: relative;
  top: 27px;
  left: -1rem;
  z-index: 999;
}

p.accopunt.text-center {
  color: gray;
  font-size: 11px;
  margin-bottom: 0px;
  padding: 0px;
}
.google_id {
  width: 50px !important;
}
.lgtext {
  font-weight: 600;
  font-size: 21px;
  color: #d9b451;
}

.new-form-set {
  width: 480px;
  margin: auto;
}
.signup-page.new-css {
  overflow-y: scroll;
}
.new-width {
  max-width: 70px !important;
}
.space_canvas {
  width: 80% !important;
  border-radius: 20px 0px 0px 20px;
}

.skills_txt {
  font-size: 12px;
}

p.bold_hedding {
  color: gray;
  font-size: 15px;
  font-weight: 500;
}
p.gay_title {
  color: gray;
  font-size: 13px;
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.inputWrapper {
  position: relative;

  .fa {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #84818a;
  }
}

.modal-title,
.dropdown-item {
  text-transform: capitalize;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #84818a !important;
  opacity: 1 !important;
  /* Firefox */
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000 !important;
  opacity: 1 !important;
}

.loaderDiv {
  z-index: 99999;
  backdrop-filter: blur(2px);
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.pageLoader {
  max-width: 50px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a0a4a8;
  opacity: 1;
  font-size: 14px;
  font-weight: 400;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a0a4a8;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a0a4a8;
}

main.main {
  background-color: #f6f6f6 !important;
}

// Loader start
img.loaderlogo {
  max-width: 50px;
  // animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

// Loader end
.gogl {
  color: grey;
}
.btn {
  text-transform: capitalize;
  font-weight: 500;
  padding: 7px 13px;
  font-size: 14px;
}
.dark-button {
  width: 120px;
}
i.fa.fa-edit.mr-2 {
  color: #31a3d9;
}
.lokm {
  border-radius: 0px !important;
}
button.btn.btn-outline-primary.header_like {
  padding: 9px 9px;
  font-size: 22px;
  display: flex;
  align-items: center;
  border: 1px solid #73737385;
  background: #ffff !important;
}
.col-md-12.border-top.border-bottom.mt-3.pt-3.mb-4.pb-3 {
  background: #31a3d903;
  // margin-bottom: 0px !important;
  margin-top: 0px !important;
  border-radius: 20px;
  margin-top: 0px !important;
  border-radius: 20px;
}

// job search

.top_box {
  border: 1px solid #dedede;
  border-radius: 20px;
  padding: 16px 19px;
  height: 137px;
}
.fav_fill {
  color: #00a5d9 !important;
}
h2.best_match {
  font-size: 24px;
}
.card-header.bg-white {
  border-radius: 15px 15px 0px 0px !important;
}
.daycls {
  font-size: 20px;
  font-weight: 600;
}

.messagcls {
  font-size: 30px;
  font-weight: 600;
  color: #000000d9;
}

.sidebox {
  border-radius: 20px;
}

.price_locals {
  font-size: 20px;
}
.price_local {
  font-size: 14px;
}
ul.project_detail_ul {
  padding-left: 15px;
  line-height: 20px;
}
// .space_canvas{
//   width: 60% !important;
// }
.col-md-12.border-top.border-bottom.mt-3.pt-3.mb-3.pb-3:hover {
  background: #31a3d90d;
}
.card {
  border-radius: 15px;
}
.badges_project span {
  background: #d5d5d561;
  padding: 4px 13px;
  margin-right: 10px;
  margin-top: 14px !important;
  display: inline-block;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 600;
  color: #00000091;
}

.badges_project span:hover {
  background: #d5d5d5bf;
}

.btn-outline-primary {
  color: #31a3d9;
  border-color: #31a3d9;
}
.right_assistent.align-items-center.d-flex span {
  color: #8b8b8b;
}
.profiledetailscls P {
  line-height: 20px;
  font-size: 14px;
}

a {
  cursor: pointer;
}

.taableWrapper {
  max-height: calc(100vh - 250px);
}

button.btn.btn-primary.loginclass {
  background: #d9b451 !important;
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 15px;
  color: #000 !important;
  border: 0 !important;
}
.btn-primary:hover {
  background-color: #791330 !important;
  border-color: #791330 !important;
}
.imagethumbWrapper.profile-main-img img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  display: block;
}
.title-head {
  background: #f2f2f2;
  padding: 10px;
  border-bottom: 1px solid #dbdbdb;
}
.product-detail-page {
  padding: 20px;
}
.edit-btn i {
  color: #fff !important;
  margin-right: 5px;
}
.dropdown-menu-right .dropdown-item:hover {
  color: #791330 !important;
}
@media (max-width: 768px) {
  .taableWrapper {
    max-height: calc(100vh - 300px);
  }
}

.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  > .container {
    max-width: 500px;

    .shadow {
      border-top: 3px solid $primaryColor;
    }

    .login-logo {
      width: 100%;
      max-width: 150px;
    }
  }
}

.bginput {
  border: 1px solid #e8e8e8 !important;
  // background: #F6F6F6 !important;
  border-radius: 8px !important;
}

.empty-box {
  padding: 15px;
  text-align: center;

  .icon-box {
    padding: 15px 30px;
    font-size: 26px;
    margin-bottom: 10px;
  }
}

h3.text-left.lgtext {
  font-weight: 600;
  font-size: 20px;
  color: #d9b451;
}

.paraclss {
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: #775da6 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #f6f6f6;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #f6f6f6;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #f6f6f6;
}

.modal {
  overflow-y: auto;

  .card-body,
  .modal-boldy {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
}

.upload-btn {
  position: relative;

  > input {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.pagination {
  > li {
    padding: 2px;

    a {
      padding: 7px 10px;
      display: inline-block;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      font-size: 14px;
      min-width: 40px;
      text-align: center;
      color: #000;
      text-decoration: none;
    }

    &.active {
      a {
        background-color: $primaryColor;
        border-color: $primaryColor;
        color: #fff;
      }
    }

    &.disabled {
      a {
        border-color: #e8e8e8;
        color: #e8e8e8;
      }
    }
  }
}

.shine {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  min-height: 20px;
  border-radius: 5px;
}

.shine.shineCard {
  height: 250px;
}

strong.shine {
  display: block;
  margin-bottom: 14px;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: middle;
}

// edit icon css
i.fa.fa-pencil-alt.edit {
  margin: 0px 15px;
  font-size: 18px;
  color: #7561a2;
  font-weight: 700;
}

i.fa.fa-trash {
  color: #c02e2e;
}

.filterFlex {
  white-space: nowrap;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
}

.checkPrice {
  column-gap: 10px;
  span {
    display: inline-block;
    input {
      margin-right: 5px;
    }
  }
}
i.fa.fa-eye-slash.slashicon {
  position: absolute;
  right: 14px;
  top: 70% !important;
  transform: translateY(-50%);
  color: #84818a;
}

i.fa.fa-eye.eyeicon {
  position: absolute;
  right: 14px;
  top: 70% !important;
  transform: translateY(-50%);
  color: #84818a;
}

// Responsive Design
.buttons_Section {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.cardList {
  margin-top: 2rem;
}

a.btn.btn-primary.mr-2 {
  white-space: nowrap;
}

.form-control {
  // padding: 21px 10px;
  border: 1px solid #dcdcdc;
  border-radius: 0px;
  &.invalid {
    border-color: red !important;
  }
}

select.form-control.types {
  height: 43px;
  margin-right: 12px;
  width: 100%;
  max-width: 200px;
}
p.phone_no {
  color: #84818a;
}
.new_cards {
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  max-height: 300px;
  // margin-bottom: 4.2rem;
}

input.searchbar.mr-2 {
  padding: 8px;
  border-color: #cdd4da;
  border-radius: 5px;
  outline: none;
  border: 1px solid #cdd4da;
  height: 43px;
}

.d-flex.justify-content-between.align-items-center {
  flex-wrap: wrap;
}

// profile changes
input.searchbar.mr-2:focus {
  box-shadow: 0 3px 10px 0 17%;
  border-color: #7561a2;
}

a.btn.btn-primary.profiles {
  background-color: #fff !important;
  border: 1px solid #c2c2c2 !important;
  height: auto;
  color: #2020209c !important;
  border-radius: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

i.material-icons.prob {
  font-size: 18px;
  color: #fff;
  margin-top: 5px;
}
.btn:focus-visible {
  box-shadow: none !important;
}
.btn.btn-secondary.dropdown-toggle.heder_drop:focus {
  border: none !important;
  box-shadow: none !important;
}

label.btn.btn-primary.delete.ml-3 {
  background-color: #fff !important;
  border-radius: 10px;
  color: #4ba5d9 !important;
  font-size: 14px !important;
  font-weight: 500;
}

label.btn.btn-primary.edit.ml-3 {
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
}

.btn.btn-secondary.discard {
  background-color: #fff !important;
  border: 1px solid #dcdcdc !important;
  color: #202020 !important;
  font-size: 14px !important;
  font-weight: 500;
}

a.discard {
  text-decoration: none;
  color: #202020 !important;
  font-size: 14px;
  font-weight: 500;

  & :hover {
    color: #202020 !important;
    font-size: 14px;
    font-weight: 500;
  }
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #31a3d9 !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-deny {
  background-color: #ea5c32 !important;
}
.btn.btn-primary.reset {
  background-color: #fff !important;
  border: 1px solid #dcdcdc !important;
  color: #202020 !important;
  font-size: 14px !important;
}

button.btn.btn-primary.edit.ml-3 {
  font-weight: 600;
  font-size: 14px;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: #202020 !important;
  --bs-table-bg-type: rgb(249 249 249 / 100%) !important;
}

a.btn.barlink.text-primary.active {
  outline: none;
  border: none;
}

a.btn.barlink.text-primary:active {
  border: none;
}

p.profile_data {
  margin-bottom: 0px !important;
  font-size: 13px;
  font-weight: 400;
  color: #5a5a5a;
  height: 39px;
}
.profile_data .profileImagemain {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}
.col-md-12.inputFlex {
  line-height: 30px;
}

.highlightOption:not(:hover) {
  background: initial;
  color: initial;
}
.delete-modal h5 {
  color: #242424;
}

// Responsive css:

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .sales_section {
    padding: 20px;
  }

  .graph_section {
    padding: 20px;
  }

  .min-sidebar ~ div > .main-sidebar i {
    margin-right: 0px !important;
  }
}

@media only screen and (min-width: 1106px) and (max-width: 1199px) {
  .sales_section {
    padding: 20px;
  }

  .graph_section {
    padding: 20px;
  }

  .min-sidebar ~ div > .main-sidebar i {
    margin-right: 0px !important;
  }

  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1105px) {
  .min-sidebar ~ div > .main-sidebar i {
    margin-right: 0px !important;
  }

  .dropdown.addDropdown.reseller_status {
    margin-top: 20px;
  }

  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1000px) {
  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }

  .min-sidebar ~ div > .main-sidebar {
    display: none !important;
  }

  .main-navbar.min-sidebar {
    padding-left: 60px;
    display: flex;
  }

  // .main-sidebar {
  //   position: absolute !important;
  //   top: 143px;
  //   z-index: 998;
  // }

  .main-navbar {
    padding-left: 25px;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }

  .min-sidebar ~ div > main.main {
    width: 100% !important;
  }

  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }

  article.d-flex {
    margin-top: 1rem;
  }

  .main-wrapper main.main {
    width: 100% !important;
  }

  .d-flex.justify-content-between.align-items-end.mb-3 {
    display: block !important;
  }

  .selectDropdown::placeholder {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  span {
    font-size: 14px;
  }

  span.side_head {
    font-size: 14px;
  }

  .main-navbar {
    padding-left: 60px !important;
  }
}

// responsive css//

// @media(min-width:768px){
//   .main-navbar.min-sidebar ~ div > .main-sidebar{
//     display: block !important;
//   }
// }

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .main-navbar.min-sidebar ~ div > .main-sidebar {
    display: none !important;
  }

  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }
}

@media screen and (max-width: 767px) {
  .phTop {
    margin-top: 10px;
  }
  .d-flex.align-items-center.Proposals_bottom {
    display: block !important;
    line-height: 2;
  }
  .border_total_sale {
    border: none;
  }
  .right.d-flex.align-items-center {
    display: block !important;
    padding: 10px;
  }
  .right.d-flex.align-items-center div {
    margin-left: 0px !important;
    margin-bottom: 5px;
  }
  .row.position-relative {
    padding: 15px;
  }
  .border-right {
    border-right: none !important;
  }
  .bg-white.pt-4 .container.pl-5.pr-5.mt-5 {
    margin-top: 0px !important;
    height: 100vh;
  }
  span.bg_bages {
    margin-bottom: 10px !important;
    display: inline-block;
    margin-right: 5px !important;
  }
  img.uploadimage {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
  .d-flex.align-items-center.Proposals_bottom p {
    margin: 0px !important;
  }
  .footer_btn {
    background: #fff;
    position: fixed;
    bottom: 0px;
    padding: 14px 2rem;
  }
  a.edit_job_post {
    width: fit-content;
    float: right;
  }
  a.profiles_edit {
    bottom: 0px !important;
  }

  .rate_from input {
    width: 90px;
    text-align: right;
  }
  a.fav_icon_revealeant.bg-white {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .col-md-9.pr-5.mt-3 {
    padding-right: 15px !important;
  }
  .col-md-9.head_cls {
    margin-bottom: 20px;
  }

  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }
  .min-sidebar ~ div > .main-sidebar a.nav-link.hoverclass {
    margin: 0 !important;
    width: unset;
  }
}

@media only screen and (min-width: 602px) and (max-width: 990px) {
  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }

  .main-navbar {
    padding-left: 60px !important;
  }

  .min-sidebar ~ div > .main-sidebar {
    display: none !important;
  }

  .main-navbar.min-sidebar {
    // padding-left: 60px;
    display: flex;
  }

  // .main-sidebar {
  //   position: absolute !important;
  //   top: 144px;
  //   z-index: 998;
  //   max-height: 770px;
  //   height: 100%;
  //   overflow: auto;
  // }

  .main-navbar {
    padding-left: 60px;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }

  .min-sidebar ~ div > main.main {
    width: 100% !important;
  }

  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }

  article.d-flex {
    margin-top: 1rem;
  }

  .main-wrapper main.main {
    width: 100% !important;
  }

  .d-flex.justify-content-between.align-items-end.mb-3 {
    display: block !important;
  }

  .selectDropdown::placeholder {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .selectDD {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .col-12.col-sm-12.col-md-12.col-lg-6.cls {
    margin-top: 15px;
  }

  // span {
  //   font-size: 14px;
  // }

  span.side_head {
    font-size: 14px !important;
  }

  .card.adds {
    margin-top: 20px;
  }
}

@media screen and (min-width: 691px) and (max-width: 854px) {
  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }

  .dropdown.addDropdown.reseller_name {
    margin-top: 0px !important;
  }

  .dropdown.addDropdown.reseller_status {
    margin-top: 20px;
  }
}

@media screen and (max-width: 690px) {
  .dropdown.addDropdown.reseller_name {
    margin-top: 20px !important;
  }

  .dropdown.addDropdown.reseller_status {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 528px) and (max-width: 600px) {
  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }

  .main-navbar {
    padding-left: 60px !important;
  }

  .min-sidebar ~ div > .main-sidebar {
    display: none !important;
  }

  .main-navbar.min-sidebar {
    padding-left: 60px;
    display: flex;
  }

  .main-navbar {
    padding-left: 60px !important;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none;
  }

  .min-sidebar ~ div > main.main {
    width: 100% !important;
  }

  // category css
  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }

  article.d-flex {
    margin-top: 1rem;
  }

  .d-flex.justify-content-between.mb-3 {
    display: block !important;
  }

  p.mb-0 {
    font-size: 13px;
  }

  img.listIc {
    height: 40px;
    width: 40px;
  }

  form.headerSearch.ml-3 {
    width: auto;
    max-width: 130px;
  }

  // .ml-3 {
  //   width: 300px;
  // }
  span {
    font-size: 14px;
  }

  span.side_head {
    font-size: 14px;
  }

  article.d-flex {
    margin-top: 1rem;
    flex-wrap: wrap;
  }

  button#dropdownMenuButtonstatusDropdown\ reset {
    margin-top: 15px;
  }

  // new css 19-07-2023//
  .dropdown.addDropdown.reseller_name {
    margin-top: 20px !important;
  }

  .dropdown.addDropdown.reseller_status {
    margin-top: 20px;
  }
}

@media screen and (max-width: 590px) {
  button.btn.btn-primary.btnreset {
    margin-top: 15px !important;
  }

  a.btn.btn-primary.btnreset {
    margin-top: 15px !important;
  }

  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 527px) {
  .shadow.bg-white.p-3.text-center.roundedBorder.repeat {
    margin-top: 15px;
  }

  .main-navbar {
    padding-left: 60px !important;
  }

  .min-sidebar ~ div > .main-sidebar {
    display: none !important;
  }

  form.pprofile1.form-row.py-3.w-50.mx-auto {
    width: 100% !important;
  }

  .text-center.d-flex.justify-content-between.mt-5 {
    display: flex !important;
  }

  p.accopunt {
    text-align: right;
    right: 20px;
    padding: 0rem 0rem !important;
    font-size: 16px;
    font-weight: 400;
    top: 8px;
  }

  p.accopunts {
    position: relative !important;
  }

  .google_id {
    width: 48%;
  }

  a.btn.barlink.text-primary {
    left: 19px;
    width: 35px;
    height: 35px;
    padding: 7px 9px;
    top: 17px;
  }

  .main-sidebar {
    position: absolute !important;
    top: 143px;
    z-index: 998;
  }

  .main-navbar.min-sidebar {
    padding-left: 40px;
    display: flex;
  }

  .main-navbar {
    padding-left: 40px !important;
  }

  .headerSearch .Searchbar {
    width: 100%;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none !important;
  }

  .min-sidebar ~ div > main.main {
    width: 100% !important;
  }

  // proffile dropdown
  .dropdown.ml-auto {
    // top: -30px;
    // right: -16px;
  }

  .headerSearch {
    top: 20px !important;
  }

  // main p-age

  .d-flex.justify-content-between.align-items-end.mb-3 {
    display: block !important;
  }

  .nav-tabs {
    border-radius: 3px !important;
    height: 40px !important;
    background: none !important;
    border: none !important;
    margin-top: 1rem;
  }

  .icons_tab {
    margin: 0px !important;
  }

  .nav-tabs {
    border-bottom: 1px solid #ffffff;
  }

  article.d-flex {
    display: block !important;
    margin-top: 1rem !important;
  }

  .new_cards {
    height: 100% !important;
    margin-bottom: 0rem !important;
    max-height: 500px !important;
    //  height: 100%;
    //   margin-bottom: 0rem !important;
  }

  li.list_names {
    text-align: left !important;
  }

  .user_proff {
    display: block !important;
  }

  // category css
  .d-flex.justify-content-between.align-items-end.mb-0 {
    display: block !important;
  }

  select.form-control.types {
    margin-top: 13px;
    width: 100%;
  }

  .d-flex.justify-content-between.mb-3 {
    display: block !important;
  }

  .d-flex.justify-content-between {
    display: block !important;
  }

  button.btn.btn-primary.btnreset {
    margin-top: 15px;
  }

  // dashboard

  h2.mb-1.mainHeading_cls {
    font-size: 20px;
    font-weight: 600;
  }

  p.grayCls.mb-0 {
    font-size: 12px;
    font-weight: 200;
    color: #898989;
    width: auto !important;
  }

  a.btn.barlink.text-primary {
    // left: 8px;
    // width: 35px;
    // height: 35px;
    // padding: 7px 9px;
    left: 19px !important;
    width: 35px !important;
    height: 35px !important;
    padding: 7px 9px !important;
    top: 17px !important;
  }

  form.headerSearch.ml-3 {
    width: 100% !important;
    margin-left: 9px !important;
  }

  p.mb-0 {
    white-space: nowrap;
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // .ml-3 {
  //   width: 200px;
  // }
  img.listIc {
    height: 40px;
    width: 40px;
  }

  .borderBox.d-flex.align-items-center.justify-content-between {
    padding: 0px 15px;
  }

  .timeBoxs p {
    font-size: 14px;
    color: #484848;
    line-height: 17px;
  }

  .headerSearch .Searchbar {
    width: 100%;
  }

  // form.headerSearch.ml-3 {
  //   width: 130px;
  // }

  // dashboard responsive
  .graph_range {
    display: block;
  }

  p.view_graph {
    margin-top: 18px;
  }

  // admin user
  .d-flex.justify-content-between.align-items-center {
    display: block !important;
  }

  span {
    font-size: 14px;
  }

  span.side_head {
    font-size: 14px;
  }

  .dropdown.addDropdown {
    margin-top: 15px;
  }

  // new css 19-07-2023
  .hedding {
    margin-bottom: 15px !important;
  }

  h2.mb-5 {
    font-size: 18px !important;
    margin-bottom: 20px !important;
  }

  .dropdown.addDropdown.booking_system.mr-2.w-100 {
    margin-top: 0px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .hedding {
    margin-bottom: 20px !important;
  }

  .text-center.tableHeading h2 {
    font-size: 23px !important;
  }

  .sidebar-brand.p-3.pt-4.text-left.pl-5 {
    display: none !important;
  }

  .min-sidebar ~ div > .main-sidebar a.col_side:hover {
    justify-content: center;
    margin: 8px 14px !important;
  }

  .min-sidebar ~ div > .main-sidebar {
    display: block !important;
    max-width: 250px;
    min-width: 250px !important;
    // min-height: 100vh;
    height: 100%;
    margin-top: -72px;
    border-right: 1px solid #e8e8e8;
    position: absolute;
    z-index: 1;
    top: 145px;
    max-height: 770px;
    overflow: auto;
  }

  .sidees {
    display: flex;
  }

  .min-sidebar ~ div > .main-sidebar a.nav-link.hoverclass {
    padding: 9px 16px;
    border-radius: 12px !important;
    margin-right: 10px !important;
    display: flex !important;
    justify-content: left !important;
    width: auto !important;
  }

  .min-sidebar ~ div > .main-sidebar a.col_side {
    padding: 9px 16px;
    border-radius: 12px !important;
    margin-right: 10px !important;
    display: flex !important;
    justify-content: space-between !important;
    width: auto !important;
  }

  .main-navbar {
    padding-left: 50px !important;
  }

  .btn.btn-secondary.discard {
    background-color: #fff !important;
    border: 1px solid #dcdcdc !important;
    color: #202020 !important;
    font-size: 14px !important;
    font-weight: 500;
  }

  .min-sidebar ~ div > .main-sidebar .nav-link span {
    display: block !important;
  }

  .min-sidebar ~ div > .main-sidebar a.col_side span {
    display: flex !important;
    font-size: 14px !important;
    font-weight: 300 !important;
    color: #202020;
  }

  .min-sidebar ~ div > .main-sidebar i {
    margin-right: 8px !important;
  }

  .main-sidebar {
    display: none !important;
  }

  .main-sidebar .nav .nav-item .nav-link i.sidenv {
    display: none !important;
  }

  .card.card-body.sides li a {
    border-radius: 4px !important;
    padding-left: 42px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
  .main-navbar {
    padding-left: 25px !important;
  }

  a.btn.barlink.text-primary {
    left: 3px !important;
    width: 35px !important;
    height: 35px !important;
    padding: 7px 9px !important;
    top: 20px !important;
  }

  button:focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none !important;
  }
}

.disabledbutton {
  cursor: not-allowed !important;
}

.messageUnread {
  position: absolute;
  margin-top: -7px;
  margin-left: 0px;
  background-color: #ea5c32;
  border-radius: 50%;
  width: 22px;
  padding: 1px;
  text-align: center;
  box-shadow: 1px 1px #787878;
  color: white;
  animation-name: Myani;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
@keyframes Myani {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.2;
  }
}

.dashboardbox {
  width: 200px;
  height: 100px;
  border: 1px solid black;
  padding: 10px;
  margin: 10px;
}

.disabled {
  cursor: not-allowed !important;
}

// New CSS
/* src/components/Homepage.css */

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  text-decoration: none;
}

// Common CSS
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif;
}

h1 {
  font-size: 52px;
  line-height: 60px;
  font-weight: 700;
  color: #fff;
}

.section-padding {
  padding: 100px 0;
}

p {
  // color: #ededed;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.6px;
  letter-spacing: -0.24px;
}

h2 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  color: #ededed;
}

h5 {
  color: #ededed;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.2px;
  letter-spacing: -0.486px;
}

h6 {
  color: #ededed;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
}

// Header CSS

header {
background-color: transparent !important;
  padding: 0 0 10px 0;
  }

.header-logo img {
  max-width: 90px;
  width: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 100%;
  object-fit: contain;
}

.header-top input {
  background: transparent;
  border-bottom: 1px solid #fff !important;
  border-radius: 0 !important;
  padding: 0 0 0px 0;
  border: 0;
  height: 30px;
}

.header-top input::placeholder {
  color: #fff !important;
}

.header-top a {
  color: #fff !important;
}
.header-top a:hover {
  color: #C1A14A !important;
}

.header-top {
  padding: 10px 0 20px 0;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
  // background: linear-gradient(90deg,RGB(48 45 45 / 35%) 0%,RGB(0 0 0 / 75%) 35%);
}

.header-top img {
  width: 13px;
  margin: 0 7px 0 0;
}

.header-top > * {
  margin-left: 22px;
}

.header-bottom .navbar-light {
  background: #141414;
  border-radius: 8px;
  box-shadow: 0px -10px 20px 0px rgba(0, 54, 110, 0.06);
}

.dark-btn {
  background: #7e1e2d;
  color: #fff;
  max-width: 180px;
  width: 100%;
  border-radius: 8px;
  display: inline-block;
  height: 50px;
}
.dark-btn:hover,
.dark-btn:focus {
  background: #7e1e2d !important;
  color: #fff;
}
.footer-copyright p {
  margin: 0 !important;
}
.header-logo a {
  display: inline-block;
  height: 100%;
}

.header-logo {
  height: 100%;
  margin-top: 7px;
}

.dark-header .navbar-light .navbar-nav .nav-link {
  color: #fff;
  display: flex;
  align-items: center;
}

.dark-header .navbar-light .navbar-nav .active > .nav-link {
  color: #fff;
}

.dark-header .navbar-light .navbar-nav li a:hover {
  border-radius: 0px !important;
  color: #fff;
  background-color: transparent;
}
.dark-header input.form-control:focus {
  background: transparent;
}
.dark-header
  .navbar-light
  .navbar-nav
  .dropdown-menu.box-shadow-unset.show
  li
  a {
  color: #000 !important;
}

.dark-header
  .navbar-light
  .navbar-nav
  .dropdown-menu.box-shadow-unset.show
  li
  a:hover {
  background-color: #82222e !important;
  color: #fff !important;
}

.header-bottom .dropdown-menu.show {
  box-shadow: none !important;
}
.mobile-show {
  display: none;
}

.mobile-hide {
  display: flex;
  width: 220px;
}

.mobile-hide span#basic-addon1 {
  font-size: 12px;
  padding: 0;
}

.mobile-show input[type="search"] {
  border-radius: 0 !important;
  border: 1px solid #000;
  border-top: 0;
  background: transparent;
  border-left: 0;
  border-right: 0;
  max-width: 155px;
  width: 100%;
}

.select-new {
  background: transparent;
  border: 0;
  appearance: auto;
  margin: 0 10px 0 0;
  color: #fff !important;
  width: 100px;
}

.select-new:focus {
  background-color: transparent;
  border-color: transparent !important;
  outline: 0;
}

.select-new option {
  color: #000;
}

span#basic-addon1 {
  font-size: 12px;
  background: transparent;
  border: 0;
  padding: 0;
  border-bottom: 1px solid #fff;
  border-radius: 0 !important;
  margin: 0 !important;
}

.login-btn {
  color: #fff !important;
  margin: 0 20px 0 10px;
}
.login-btn:hover {
  color: #fff;
}
// Banner section

.banner-inner img {
  max-width: 700px;
  margin: 0 auto;
  display: block;
  width: 100%;
}
.banner-inner p {
  color: #fff !important;
}

// Feature Section

.feature-section {
  background: #7e1e2d;
  border-bottom: 2px solid #d9b451;
  border-top: 2px solid #d9b451;
}

.feature-inner {
  text-align: center;
}

.feature-inner p {
  margin: 0;
}

.feature-inner h2 {
  margin: 20px 0;
}

.feature-img {
  background: #d9b451;
  width: 70px;
  height: 70px;
  margin: 0 auto;
  border-radius: 50%;
}

.feature-img img {
  width: 28px;
  height: 100%;
  margin: 0 auto;
}

.feature-content {
  margin: 60px 0 0 0;
}

.feature-pointers h5 {
  margin: 23px 0 5px 0;
}

.feature-pointers p {
  max-width: 300px;
  margin: 0 auto;
  opacity: 0.56;
}

// Digital Transactions

.transc-title h2 {
  color: #d9b451;
}

.digital-transaction {
  background: #212121;
}

.transc-inner ul li {
  list-style: none;
  padding: 24px 24px 24px 36px;
  border-left: 4px solid #fff;
}

.transc-inner ul li p {
  margin: 0;
  color: rgba(237, 237, 237, 0.6);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}

.transc-title {
  margin: 0 0 80px 0;
}

.transc-inner ul {
  padding: 0;
}

.transc-inner h6 img {
  margin: 0 12px 0 0;
}

.transc-inner ul li:hover {
  background: rgba(237, 237, 237, 0.3);
}

.active-transaction {
  background: rgba(237, 237, 237, 0.3);
}

.transc-inner h6 {
  display: flex;
  align-items: center;
}

.active-transaction {
  border-left: 4px solid #7e1e2d !important;
}

.transc-inner ul li:hover {
  border-left: 4px solid #7e1e2d;
}

.digital-trans-img img {
  width: 100%;
}

.digital-trans-inner h2 {
  color: #d9b451;
}

.digital-trans-inner {
  max-width: 374px;
  margin: 0 0 0 auto;
}

.section-inner-padding {
  padding: 0 0 100px 0;
}

.margin-bottom-100 {
  margin: 0 0 60px 0;
}

.transc-img img {
  width: 100%;
}

// Testimonial Section
.testimonial-section {
  background: #7e1e2d;
  border-bottom: 3px solid #d9b451;
  border-top: 3px solid #d9b451;
}

.testimonial-title {
  text-align: center;
  margin: 0 0 60px 0;
}

.testimonial-title p {
  margin: 0;
  color: #fff;
}

.testimonial-inner {
  border-radius: 24px;
  background: #050505;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.testimonial-img {
  // background-image: url(http://localhost:3000/static/media/testimonial-bg.a19240d3cb10e5506631.png);
  background-repeat: no-repeat;
  padding: 40px;
  background-size: cover;
  background-position: initial;
}

.testimonial-img img {
  max-width: 200px;
  margin: 0 auto;
  display: block;
}

.testimonial-content {
  height: 100%;
  color: rgba(255, 255, 255, 0.6);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.6px;
  display: flex;
  justify-content: center;
  max-width: 453px;
  align-items: flex-start;
  padding: 0 0 0 20px;
  flex-direction: column;
}

.testimonial-content span {
  color: #d9b451;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.24px;
}

.highlighted-title {
  border-radius: 20px;
  display: flex;
  max-width: 140px;
  color: #000f14;
  justify-content: center;
  text-align: center;
  padding: 3px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  align-items: center;
  margin: 0 auto 40px auto;
  font-weight: 600;
  line-height: 25.6px;
  letter-spacing: -0.24px;
  background: #f1f3f3;
}

.highlighted-title p {
  width: 12px;
  height: 12px;
  background: #0c438f;
  border-radius: 50%;
  margin: 0 20px 0 0;
}

.highlighted-title h4 {
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.6px;
  letter-spacing: -0.24px;
  margin: 0;
}

// FAQ

.faq_wrapper {
  border-bottom: 3px solid #d9b451;
  // background: url(../../../public/assets/img/faq-bg.png);
  background-position: bottom;
  background-size: cover;
  background-color: #000;
  background-attachment: fixed;
}
.faq_wrapper .accordion-header button {
  background: transparent;
  color: #ededed !important;
  padding-left: 0;
  padding-right: 0;
}

.faq_wrapper .accordion-button:focus {
  border: 0 !important;
  box-shadow: unset !important;
}

.faq_wrapper .accordion-button::after {
  filter: invert(1);
}

button.accordion-button.collapsed::after {
  filter: invert(1);
}

.faq_wrapper .accordion-item {
  // border: 1px solid #ededed;
  border-left: 0;
  border-radius: 0;
  border-right: 0;
}

.faq_wrapper .accordion-header {
  background: transparent !important;
  border-radius: 0;
}

.faq_wrapper .accordion-body {
  padding: 20px 0px !important;
  color: #ededed !important;
}

.faq_wrapper .accordion-button::after {
  color: #fff;
}

.faq_text h2 {
  color: #d9b451;
}

span.lblue {
  color: #fff;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  text-decoration-line: underline;
}

// CTA Section
.cta-section {
  text-align: center;
  background: #7e1e2d;
}

.black-btn {
  background: #000;
  padding: 13px 20px;
  border-radius: 8px;
  color: #fff;
  display: inline-block;
  max-width: 200px;
  width: 100%;
  border: 1px solid#D9B451;
}
.black-btn:focus {
  box-shadow: none;
}
.black-btn:hover {
  background: #000 !important;
  padding: 13px 20px !important;
  border-radius: 8px !important;
  color: #fff !important;
  display: inline-block !important;
  // max-width: 180px !important;
  width: 100% !important;
  border: 1px solid#D9B451;
}

.cta-section-inner p {
  max-width: 600px;
  margin: 40px auto;
  color: #fff;
}

.cta-section-inner h2 {
  color: #d9b451;
  font-weight: 600;
}

// Benefit Section
.benefits-section {
  background: #000;
}

.bg-inner-img img {
  border-radius: 30px;
  width: 100%;
  height: 320px;
  object-fit: cover;
}

.benefits-inner h2 {
  color: #d9b451;
  margin: 0 0 80px 0;
}

.optimize-title {
  margin: 100px 0 0 0;
}

.yellow_color {
  color: #be9d47;
}

.optimize-title {
  display: flex;
  justify-content: space-between;
}
.optimize-operations p {
  color: #fff;
}

.optimize-title p {
  width: 40%;
  margin: 0 0 0 auto;
}

.optimize-title h2 {
  width: 50%;
}

.optimize-operations .goals-points li {
  border-bottom: 1px solid #d9b451;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0;
}

.optimize-operations .goals-points li h6 {
  width: 50%;
  margin: 0;
  font-weight: 600;
  font-size: 17px;
}

.optimize-operations .goals-points li:last-child {
  border: 0;
}

.benefits-listing {
  width: 40%;
}

.benefits-listing p {
  margin: 0;
  font-size: 15px;
}

.business-goal li {
  display: flex;
  justify-content: space-between;
}

.business-goal li p {
  width: 40%;
  margin: 0;
  color: #d9b451;
  font-size: 15px;
  line-height: 22px;
}

.optimize-operations ul {
  padding: 0;
  margin: 0;
}

.equal-margin p {
  margin: 0 0 30px 0;
  line-height: 22px;
}

.equal-margin p:last-child {
  margin: 0;
}

.business-goal li {
  border-bottom: 1px solid #d9b451;
  padding: 20px 0;
}

// Custom Products
.custom-products {
  background: #000;
}

.custom-product-content h3 {
  margin: 0;
  color: #fff;
  font-size: 38px;
  max-width: 449px;
}

.main-container {
  background: #212121;
  height: 50vh;
  overflow: hidden;
  position: relative;
}

.main-container .container,
.main-container .mobile-img-section,
.main-container .row,
.main-container .col-lg-6,
.custom-product-content {
  height: 100%;
}

.custom-product-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-img {
  position: absolute;
  right: 0;
}

.mobile-img img {
  width: 100%;
  display: block;
  margin: 0 0 0 auto;
}

.custom-product-content h3 {
  margin: 0;
}
.inputflex {
  display: flex;
  column-gap: 10px;
}
.inputflex > label {
  width: 200px;
  font-weight: 600 !important;
}
.top-padding {
  padding: 200px 0 50px 0;
}
.view-profile-bg {
  width: 100%;
}
.view-profile-bg p {
  background: #f2f2f2;
  padding: 6px 12px;
  border-radius: 5px;
}
// Footer
.footer-section {
  background: #7e1e2d;
  padding: 10px;
}

.footer-upper {
  padding: 20px 0;
}

.footer-address ul li {
  list-style: none;
}

.footer-address ul li a {
  display: flex;
  align-items: center;
  color: #fff;
}
.footer-address ul li a:hover {
  color: #fff !important;
}
.footer-address ul li a p {
  margin: 0;
}

.footer-feature ul li {
  list-style: none;
  margin: 10px 0;
  color: #ededed;
  font-size: 14px;
}

.footer-address ul li > * {
  margin-bottom: 5px;
}

.footer-title p {
  color: rgba(255, 255, 255, 0.36);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.6px;
  margin: 0;
  max-width: 420px;
}

.footer-feature ul {
  padding: 0;
  margin: 0;
}

.footer-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  padding: 30px 0;
}

.footer-feature h5 {
  color: rgba(237, 237, 237, 0.36);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
  letter-spacing: -0.21px;
  margin-bottom: 20px;
}

.footer-upper .footer-title h3 {
  color: #ededed;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 43.2px;
  letter-spacing: -0.972px;
}

.footer-address ul li a img {
  margin: 0 20px 0 0;
  width: 20px;
  height: 20px;
}

.footer-address ul li a:hover {
  background: #7e1e2d;
}

.footer-copyright p {
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  color: #ededed;
}

.footer-address ul li a:hover {
  background: #7e1e2d !important;
}

.transc-img img {
  width: 100%;
  max-width: 500px;
  margin: 0 0 0 auto;
  display: block;
}

.img-right {
  position: absolute;
  right: 0;
}
// ContactUs Page
.contact-us {
  // background-image: url(../../../public/assets/img/contact-us.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.contact-us-bg {
  text-align: center;
  padding: 60px 0;
}
.contact-us-bg h2{
  font-size: 42px;
}
.contact-us-bg p{
  color: #fff;
}
.address-inner {
  display: flex;
  align-items: center;
  gap: 12px;
  box-shadow: -1px 1px 7px 0px #00000054;
  padding: 20px;
  border-radius: 12px;
}

.address-inner img {
  background: #791330;
  width: 21px;
  height: 20px;
}

.address-icon {
  background: #791330;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px 0;
}

.address-inner img {
  width: 100%;
  height: 100%;
  padding: 9px;
  border-radius: 5px;
}

.address-fields {
  margin: 50px 0;
}

.address-inner h3 {
  font-size: 20px;
  color: #222;
}

.address-inner p {
  margin: 0;
  color: #222;
  font-size: 14px;
}

.contact-us-title-inner {
  text-align: center;
  margin-bottom: 34px;
}

.contact-us-title-inner p {
  color: #222;
  margin: 0;
}

.contact-us-inner {
  border-radius: 10px;
  max-width: 60%;
  margin: 0 auto 50px;
  background: #fff3f3;
  padding: 30px;
}
.inner_hero_img {
  padding: 40px;
  border: 2px solid rgba(217, 180, 81, 1);
  background-color: rgba(20, 20, 20, 1);
  border-radius: 10px;
}

.icon_inners p {
  margin: 0px;
  color: #ededed;
  line-height: 1.3;
  font-size: 19px;
}

.icon_tick img {
  aspect-ratio: 3/2;
  object-fit: contain;
  height: 50px;
}

.icon_inners span {
  margin-bottom: 10px;
  position: relative;
  display: block;
  color: #ededed;
  letter-spacing: 0.938px;
  text-transform: uppercase;
  opacity: 0.2592;
}

.one_cloumn_icon {
  display: flex;
  justify-content: center;
  align-items: end;
}

.banking-wrapper {
  position: relative;
  width: 100%;
  top: -70px;
}

.payment_text {
  padding: 60px 0px;
}

.payment_text h2 {
  font-size: 40px;
}

.payment_text p {
  color: #d9b451;
  margin-top: 15px;
}

.issue-setup h2 {
  font-size: 40px;
  color: #d9b451;
  font-weight: 700;
}

.issue-setup {
  padding: 100px 0px 0px;
}

.issue-setup button {
  margin-top: 30px;
}

.hero-image {
  // background: url("../../../public/assets/img/heroimage.png") no-repeat center;
  background-size: cover;
  height: 100%;
  background-color: #000;
  padding-bottom: 100px;
}

.icon_tick {
  margin-right: 15px;
}

.yellow-color {
  color: #c1a14a;
  font-weight: bold;
}

.banner-section {
  padding: 200px 0px 100px !important;
}

span.star_icon p {
  margin: 0px;
}

.marginbm60 {
  margin-bottom: 40px;
}
.marginbm60 .btn{
  max-width: 180px;
}
span.star_icon {
  text-align: left;
  margin-left: 20px;
}

span.star_icon p i {
  color: #ffd36f;
  margin-right: 5px;
}

.bg_img {
  background-image: url("/assets/img/login-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  height: 100vh;
  position: relative;
}
a.right_home {
  display: flex;
  align-items: center;
  font-size: 14px;
  background: #d3b750;
  border: 1px solid;
  padding: 5px 10px;
  border-radius: 32px;
  color: #000;
  margin: 15px;
  width: 100px;
  position: absolute;
  top: 0;
}
.right_side {
  background-color: transparent;
  height: 100%;
  padding: 2rem 2rem;
  border-radius: 20px;
  border: 1px solid #d9b451;
  margin: 0 auto;
  width: 100%;
}
.forgot-pw-text h3 {
  color: #d3b750 !important;
}
.forgot-pw-text p {
  color: #fff !important;
}
p.para_forget {
  font-size: 13px;
  text-align: center;
}
// .bg_img.main_signup.signup-page {
//   height: 100% !important;
// }
.common_padding {
  padding: 60px 0px;
}

.blured {
  -webkit-filter: blur(5px);
}

.titiled {
  font-size: 20px;
  color: #000;
}

.onload-modal-small .modal1 .bank-modal {
  background: #fff0f0 !important;
  padding: 10px 0px 20px 0px;
  border-radius: 8px;
  width: 500px;
  // max-height: 400px;
  // overflow: auto;
}
.limitqty{
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 5px 3px 5px 0px;
}
.bank-modal select ,input  {
  width: 100%;
  background: #fff;
  height: 25px;
  padding: 0px 5px;
  font-size: 12px;
  border-radius: 5px !important;
  outline: 0 !important;
  border: 1px solid #8080806b;
}
.bank-modal span.close-icon {
  right: 2px !important;
  font-size: 20px !important;
  color: #000 !important;
}
.btit{
  font-size: 10px;
}
.selectp{
  font-size: 14px;
  color: #000;
  margin: 0;
  font-weight: 400;
}
.lspan {
  font-size: 10px;
  padding: 0px 4px;
}
.pprofile1 label {
  color: #000;
}
.dashboard-switch label {
  color: #000;
}
.btn-primary:hover {
  color: #fff !important;
}
.reactphonecust .react-tel-input {
  width: 100% !important;
}

// .reactphonecust .react-tel-input .selected-flag {
//   outline: none;
//   position: relative;
//   height: 100%;
//   border: 1px solid #CACACA;
//   border-right: 1px solid transparent;
//   border-radius: 50px 0px 0px 50px !important;
//   width: 40px !important;
// }

// .react-tel-input .selected-flag {
//   outline: none;
//   position: relative;
//   width: 60px !important;
//   height: 100%;
//   padding: 0 0 0 8px;
//   border-radius: 3px 0 0 3px;
//   background: #fff;
//   border: 1px solid gainsboro;
//   border-radius: 50px !important;
// }

.profile-dropdown .dropdown-toggle::after {
  display: none;
}
.profile-dropdown .dropdown-toggle {
  padding: 0;
}
.profile-dropdown .dropdown-toggle img {
  width: 100px;
}
.profile-dropdown .dropdown-toggle img {
  width: 35px;
  height: 35px;
  object-fit: cover;
}
.form-check.form-switch {
  padding: 0.375rem 0.75rem;
  white-space: nowrap;
}
.header-top input.form-control {
  color: #ffff;
}
.header-bottom .form-check-input:checked {
  background-color: #7e1e2d;
  border-color: #7e1e2d;
}
.header-top .form-check-input:focus {
  box-shadow: unset !important;
}

.sales_section {
  background: #fff;
  padding: 18px;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  max-height: 125px;
  border: 1px solid #efefef;
}
.profile-user img {
  width: 100px !important;
  height: 100px !important;
}

.w-200 {
  width: 225px !important;
}
label {
  color: #222;
}
.centerLogin label {
  color: #fff;
}
.btn-primary.focus,
.btn-primary:focus {
  box-shadow: unset !important;
}
@media only screen and (min-width: 528px) and (max-width: 600px) {
  span {
    font-size: unset !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 527px) {
  span {
    font-size: unset !important;
  }
}

// @media only screen and (min-width: 602px) and (max-width: 990px) {
//   span {
//     font-size: unset !important;
//   }
// }

@media (min-width: 768px) and (max-width: 1199px) {
  .inner_hero_img {
    padding: 15px !important;
  }

  .icon_inners span {
    font-size: 11px;
  }

  .banking-wrapper {
    top: -60px !important;
  }

  .icon_inners p {
    font-size: 15px !important;
  }
}

@media (max-width: 992px) {
  .mobile-show input{
border-color: #fff !important;
  }
  .mobile-show {
    display: flex;
  }

  .mobile-hide {
    display: none;
  }
}

@media (max-width: 991px) {
  .img-right {
    position: unset;
    right: 0;
  }

  .transc-img img {
    margin: 30px auto 0;
  }

  .digital-trans-img img {
    max-width: 500px;
    margin: 0 auto;
    display: block;
  }

  .digital-trans-inner {
    max-width: unset;
    margin: 0 auto;
    width: 100%;
  }

  .testimonial-content {
    padding: 40px;
    margin: 0 auto;
  }

  .section-padding {
    padding: 70px 0;
  }

  h2 {
    font-size: 35px;
    line-height: 50px;
  }

  .bg-inner-img {
    margin: 20px 0;
  }

  h1 {
    font-size: 35px;
    line-height: 40px;
    font-weight: 400;
  }

  .marginbm60 {
    margin-bottom: 20px !important;
  }

  .banner-section {
    padding: 50px 0px 30px !important;
  }

  .dark-btn {
    max-width: 140px;
  }
}

@media (max-width: 767px) {
  .banner-section {
    padding: 50px 0px 0px !important;
  }

  .banking-wrapper {
    position: relative !important;
    top: 0px !important;
    padding-top: 80px;
  }

  .icon_tick {
    margin-bottom: 25px;
  }

  .one_cloumn_icon {
    margin-bottom: 30px;
    display: block !important;
  }

  .banner-inner img {
    margin-top: 60px;
  }

  h1 {
    font-size: 35px;
    line-height: 40px;
    font-weight: 700;
    color: #d9b451;
  }

  .banner-inner span {
    // font-size: 35px;
    line-height: 40px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
  }

  .section-padding {
    padding: 50px 0;
  }

  h2 {
    font-size: 30px;
    line-height: 40px;
  }

  .feature-inner h2 span {
    font-size: 30px;
    line-height: 40px;
  }

  .feature-pointers {
    margin: 15px 0;
  }

  .footer-feature {
    margin: 20px 0 0 0;
  }

  .footer-address ul {
    padding: 0;
    margin: 40px 0 0 0;
  }

  .footer-upper {
    padding: 50px 0 50px 0;
  }

  .footer-upper .footer-title h3 {
    font-size: 31px;
    line-height: 41.2px;
  }

  .img-right {
    padding-right: 15px !important;
  }

  .faq_text {
    margin: 0 0 40px 0;
  }

  .optimize-title {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .optimize-title p {
    width: 100%;
    margin: 0 0 30px 0;
  }

  .business-goal li p {
    width: 100%;
    margin: 5px 0;
  }

  .business-goal li {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .optimize-operations .goals-points li {
    justify-content: flex-start;
    flex-direction: column;
  }

  .benefits-listing {
    width: 100%;
  }

  .optimize-operations .goals-points li h6 {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .change_section .change-password {
    padding: 1.4rem;
  }


}

@media (max-width: 576px) {
  .header-top a {
    font-size: 12px;
  }

  .header-top > * {
    margin-left: 10px;
  }

  .header-logo img {
    max-width: 80px;
  }
  .right_side {
    padding: 1rem;
  }
}

.user-edit-profile img {
  object-fit: cover;
  display: block;
  margin: 0 auto;
  padding-top: 0 !important;
}
.main_profile_page label {
  font-weight: 600 !important;
}

// Product Page CSS
span.pills_bar {
  background: #790734;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  margin-left: 5px;
  padding: 6px;
}
.cart-img img {
  width: 60px;
  height: 60px;
  object-fit: cover;
}
h3.cart_sel {
  font-size: 16px;
  padding: 0px;
  margin-bottom: 14px;
  color: #82222e !important;
}
.dropdown-menu.dropdown-menu-right img {
  object-fit: cover;
}
.dropdown-menu-right .dropdown-item:focus {
  // color: #fff !important;
  color: #000 !important;
}
.acordians_steps .accordion-button:not(.collapsed) {
  background-color: #790734;
  box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0
    var(--bs-accordion-border-color);
  color: #fff;
}
.heading_tab.accordion-header button {
  font-size: 15px;
  font-weight: 600;
}
.inner_listingadd {
  background: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.48);
  border-image: initial;
  border-radius: 5px;
  padding: 20px;
}
.inner_listingadd h3 {
  color: #82222e !important;
    font-size: 18px;
}
.addes_save:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.text_addsave label{
  font-weight: 500 !important;
  font-size: 15px;
}

.text_addsave h4 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
}
.text_addsave p {
  color: rgb(46, 44, 44);
  font-size: 14px;
}
.text_addsave .codes{
  font-size: 13px;
  color: #333;
  margin-bottom: 0;
}

.addes_save .text_addsave p {
  color: #2e2c2c;
  font-size: 14px;
  width: 400px;
  font-weight: 400;
  line-height: 22px;
}
.wrapper_addrespage_addres {
  margin: 20px 10px;
}
.head_normar {
  padding: 20px 5px 15px;
}
.head_normar p {
  color: #444242;
  cursor: pointer;
  margin: 0;
}
.checkbox_text {
  margin-top: 10px;
}
.checkbox_text input{
  width: auto !important;
}
.checkbox_text p {
  font-size: 14px;
  margin: 0;
}
.heading_check {
  margin-bottom: 20px;
  position: relative;
}
.heading_check h3 {
  color: #000;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.heading_check h3::before {
  bottom: 15px;
  content: "";
  height: 2px;
  left: 0px;
  position: absolute;
  width: 100px;
  background: rgb(0, 0, 0);
}
.heading_tab.accordion-header button {
  font-size: 15px;
  font-weight: 600;
  padding: 3px 20px;
}
.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.17) 0px 0px 6.519px 0px;
  margin-bottom: 30px;
  text-align: left;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 25px;
}

.card_listing {
  margin-bottom: 20px;
  border-bottom: 1px solid #cecece;
  background: #f3f3f370;
  border-radius: 10px;
  // padding: 20px;
}
.checked_cl ~ img {
  object-fit: cover;
  width: 100%;
  max-width: 134px;
  height: 133px !important;
  border-radius: 8px;
}
.detail_cl {
  color: rgb(0, 0, 0);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.18px;
  line-height: normal;
  margin-bottom: 11px;
}
.set-quentity:hover {
  background: #7e1e2d30;
  color: #fff;
  border: 1px solid #7e1e2d30;
}
.Stock_cl,
.seller_cl {
  color: rgb(51, 51, 51);
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0.238px;
  text-align: left;
}
.Stock_cl {
  margin-bottom: 11px;
  color: #212529;
  display: flex;
  gap: 12px;
}
.drop_qulity {
  font-size: 14px;
  background: rgb(245, 245, 245);
  border-width: 0.808px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.2);
  border-image: initial;
  border-radius: 5px;
  padding: 2px 10px;
}
select.qty_select {
  background: whitesmoke;
  border-width: 0px;
  border-style: initial;
  font-size: 14px;
  border-color: initial;
  border-image: initial;
}
.padding_items {
  padding: 10px 0;
}
.checked_cl input {
  height: 20px;
  width: 20px;
}
.checked_cl input {
  box-shadow: none !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgb(0, 0, 0) !important;
  border-image: initial !important;
  border-radius: 0px !important;
  outline: initial !important;
}
.checked_cl input:checked {
  background-color: rgb(109, 192, 97) !important;
}
.btn_all{
  background: #e61818;
    color: #fff !important;
    padding: 3px 10px;
    text-align: center;
    border-radius: 5px;
}
.btn_all span {
  cursor: pointer;
  font-size: 14px;
  color: #fff;
}
.drop_qulity span {
  color: #212529;
}
.address_headview h5 {
  color: #000;
  font-size: 18px;
  margin-bottom: 15px;
}
.add_lists p {
  font-size: 14px;
  color: #212529;
  margin-bottom: 5px;
}
img.iconSignup {
  width: 75px;
}
.confirmation-modal .modal-content {
  max-width: 500px;
  width: 100%;
  text-align: center;
}
.confirmation-modal .modal-body h2 {
  font-size: 34px;
  margin: 0;
}
.confirmation-modal .glads {
  font-size: 19px;
  line-height: 27px;
}
.border-bottom p {
  font-size: 14px;
  color: #212529;
  margin-bottom: 0px;
}
.product_aweosme {
  padding: 6px 0px 0px;
  display: flex;
  justify-content: space-between;
}
.profit_list {
  margin-bottom: 15px;
  border-bottom: 1px solid rgb(215, 215, 215);
}
.product_hedng {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0px;
}
.border-bottom {
  border-bottom: 1px solid #cfcfcf !important;
}
.cart_pros_totl,
p.cart_pros_footer {
  color: rgb(50, 50, 50);
  font-size: 14px;
}
.total_amt {
  font-size: 14px !important;
  font-weight: 600 !important;
  margin: 0;
  color: #323232;
}
.cart_pro_footer {
  background: #82222e14 !important;
  padding: 10px;
  text-align: start;
  margin: 0;
}

.bold_grey_store {
  margin-bottom: 0px;
}

.order_store {
  margin-bottom: 0px;
}
.blog-detail-page {
  margin: 100px 0px !important;
}

.select_product .cart_pros {
  margin-bottom: 0px;
  color: #212529;
}

.profit_list.bordertop1 {
  margin-bottom: 10px;
  border-bottom: 0px;
}

.heading_check .select_bx {
  color: #212529;
}

.checkout_text_left,
.checkout_text_right {
  font-size: 14px;
  color: #212529;
}

.payment_section .payment_img {
  width: 100%;
  height: 100%;
  max-width: 100px !important;
}

.payment_body {
  padding: 0px;
}

.space_gap {
  gap: 11px;
  justify-content: center;
}

.form-check .form-check-input[type="radio"] {
  border: 1px solid #000;
}

.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 0px !important;
  height: 100%;
  padding: 0px;
  border-radius: 0px !important;
}

//gourav css

.cart_header {
  color: #000;
  margin-right: 12px;
}
.customer_bx {
  font-size: 14px;
}
.sideleft_checkout {
  position: sticky;
  top: 197px;
}

.cart_header:hover {
  color: #691730 !important;
}

.cart_white {
  color: #fff !important;
}
.user_name {
  text-transform: capitalize;
}

// Overview page
.main_div {
  // margin-top: 152px;
  background: #000;
  padding-bottom: 140px;
}
.overview_hero {
  background-color: #000;
  height: 650px;
  position: relative;
}
.overview_img {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  mix-blend-mode: luminosity;
}
.over_parent {
  position: absolute;
  top: 50%;
  border-bottom: 5px solid #ffffff;
  width: 100px;
  display: flex;
  justify-content: center;
}
.overview_heading {
  color: #d9b451;
  font-weight: 600;
  font-size: 52px;
  white-space: nowrap;
  border-bottom: 5px solid #fff;
  display: flex;
  justify-content: center;
  width: 100px;
}
.overview_div {
  margin-top: 70px;
}
.overview_p {
  color: #ffffff;
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
}
.desc_heading {
  color: #d9b451;
  font-weight: 600;
  font-size: 40px;
}
.over1_img {
  height: auto;
  width: 100%;
  object-fit: contain;
}
.overview_div2 {
  margin-top: 140px;
}
@media (min-width: 1200px) {
  .over1_img {
    height: 400px;
    width: 430px;
  }
}
@media (max-width: 991px) {
  .header-bottom .navbar-light{
    background: none !important;
  }
  // .main_div{
  //   margin-top: 128px;
  // }
  .overview_hero {
    height: 450px;
  }
  .overview_p {
    font-size: 14px;
    line-height: 25px;
  }
  .desc_heading {
    font-size: 34px;
  }
  .colit{
    flex-direction: column;
    align-items: start !important;
  }
  .colit .select-new  {
    padding-left: 0;
    margin-left: 0 !important;
  }

  .colit .dark-btn{
    margin: 10px 0;
  }
}
@media (max-width: 767px) {
  .overview_div2 {
    margin-top: 70px;
  }
  .reverseit {
    flex-direction: column-reverse;
  }
}
@media (max-width: 480px) {
  .main_div {
    padding-bottom: 60px;
  }
  .overview_hero {
    height: 300px;
  }
  .overview_heading {
    font-size: 30px;
  }
  .over_parent {
    width: 50px;
  }
  .overview_div {
    margin-top: 30px;
  }
  .overview_p {
    font-size: 12px;
    line-height: 20px;
  }
  .overview_div2 {
    margin-top: 35px;
  }
  .desc_heading {
    font-size: 25px;
  }
}

// Features page
.features_hero {
  background-color: #000;
  height: 402px;
  position: relative;
}
.features_img {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  mix-blend-mode: luminosity;
}
.feature_parent {
  position: absolute;
  top: 50%;
  border-bottom: 5px solid #ffffff;
  width: 100px;
  display: flex;
  justify-content: center;
}
.feature_heading {
  color: #d9b451;
  font-weight: 600;
  font-size: 52px;
  white-space: nowrap;
  border-bottom: 5px solid #fff;
  display: flex;
  justify-content: center;
  width: 100px;
}

.pre_heading {
  color: #d9b451;
  font-weight: 600;
  font-size: 30px;
}
.overview_div .btn {
  font-weight: 400;
}

@media (max-width: 767px) {
  .over1_img {
    height: 320px;
    margin-top: 45px;
  }
}
@media (max-width: 480px) {
  .features_hero {
    height: 420px;
  }
  .feature_parent {
    top: 53%;
    width: 52px;
  }
  .feature_heading {
    font-size: 25px;
  }
  .pre_heading {
    font-size: 23px;
  }
  .pre_heading {
    font-size: 23px;
  }
}

// Solution page
.solution_hero {
  background-color: #000;
  height: 700px;
  position: relative;
}
.solution_img {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  mix-blend-mode: luminosity;
}
.solution_parent {
  position: absolute;
  top: 50%;
  border-bottom: 5px solid #ffffff;
  width: 100px;
  display: flex;
  justify-content: center;
}
.solution_heading {
  color: #d9b451;
  font-weight: 600;
  font-size: 52px;
  white-space: nowrap;
  border-bottom: 5px solid #fff;
  display: flex;
  justify-content: center;
  width: 100px;
}
.detaild_heading {
  color: #d9b451;
  font-weight: 500;
  font-size: 27px;
  margin-bottom: 15px;
}
.detail_desc {
  color: #fff;
  font-weight: 300;
  line-height: 24px;
  font-size: 16px;
}
@media (max-width: 767px) {
  .solution_hero {
    height: 550px;
  }
  .solution_heading {
    font-size: 40px;
  }
  .solution_parent {
    width: 82px;
  }
  .detaild_heading {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .detail_desc {
    line-height: 22px;
    font-size: 14px;
  }
}
@media (max-width: 480px) {
  .solution_hero {
    height: 445px;
  }
  .solution_parent {
    top: 58%;
    width: 60px;
  }
  .solution_heading {
    font-size: 25px;
  }
  .detaild_heading {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .detail_desc {
    color: #fff;
    font-weight: 300;
    line-height: 20px;
    font-size: 12px;
  }
  .over1_img {
    margin-top: 0px;
    width: 100%;
    height: auto;
  }
}

.features_hero {
  background: url('../../public/assets/img/feature.png') no-repeat 50%;
  background-color: #000;
  background-size: 100%;
  height: 100%;
  background-position: top;
  height: 616px;
}

//solution//
.solution_hero{
  background: url('../../public/assets/img/solution.png') no-repeat 50%;
  background-color: #000;
  background-size: 100%;
  height: 100%;
  background-position: top;
  height: 616px;
}

//careers//
.career_hero{
  background:url('../../public/assets/img/career.png') no-repeat 50%;
  background-color: #000;
  background-size: 100%;
  height: 100%;
  background-position: top;
  height: 616px;
}

.overview_hero{
  background: url('../../public/assets/img/overview.png') no-repeat 50%;
  background-color: #000;
  background-size: 100%;
  height: 100%;
  background-position: top;
  height: 616px;
}
// About-us page
.about_hero {
  background: url('../../public/assets/img/about.png') no-repeat 50%;
  background-color: #000;
  background-size: 100%;
  height: 100%;
  background-position: top;
  height: 616px;
}
.about_img {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  mix-blend-mode: luminosity;
}
.about_parent {
  position: absolute;
  top: 50%;
  border-bottom: 5px solid #ffffff;
  width: 100px;
  display: flex;
  justify-content: center;
}
.about_heading {
  color: #d9b451;
  font-weight: 600;
  font-size: 52px;
  white-space: nowrap;
  border-bottom: 5px solid #fff;
  display: flex;
  justify-content: center;
  width: 100px;
}
.set-text-about {
  height: 80%;
}
.team_card {
  background: #d9b451;
  border-radius: 10px;
}
.tcard_img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.team_div {
  padding: 15px 25px 25px 15px;
}
.card_flex {
  display: flex;
  justify-content: space-between !important;
}
.team_h {
  color: #252525;
  font-weight: 500;
  margin: 0;
  font-size: 20px;
  margin-bottom: 4px;
}
.teamh_desc {
  color: #181818;
  font-weight: 400;
  font-size: 15px !important;
}
.anywhere {
  font-weight: 500;
  color: #d9b451;
  font-size: 40px;
}

@media (max-width: 767px) {
  .about_hero {
    height: 550px;
  }
  .about_parent {
    top: 57%;
    width: 70px;
  }
  .about_heading {
    font-size: 38px;
  }
  .anywhere {
    font-size: 27px;
    margin-bottom: 25px;
  }
  .contact-us {
    height: 250px !important;
}
.contact-us-bg h2 {
    font-size: 32px !important;
    margin: 0 !important;
}
.contact-us-title-inner h4 {
  font-size: 14px !important;
}
.contact-us-inner {
  max-width: 90% !important;
}
}
@media (max-width: 480px) {
  .about_hero {
    height: 415px;
  }
  .about_parent {
    top: 61%;
    width: 45px;
  }
  .about_heading {
    font-size: 25px;
  }
  .team_div {
    padding: 10px 15px 15px 10px;
  }

  .team_h {
    font-size: 15px;
    margin-bottom: 2px;
  }
  .teamh_desc {
    font-size: 12px !important;
  }
  .anywhere {
    font-size: 17px;
    margin-bottom: 20px;
  }
}

// Career page
.career_hero {
  background-color: #000;
  height: 650px;
  position: relative;
}
.career_img {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  mix-blend-mode: luminosity;
}
.career_parent {
  position: absolute;
  top: 48%;
  border-bottom: 5px solid #ffffff;
  width: 100px;
  display: flex;
  justify-content: center;
}
.career_heading {
  color: #d9b451;
  font-weight: 600;
  font-size: 52px;
  white-space: nowrap;
  border-bottom: 5px solid #fff;
  display: flex;
  justify-content: center;
  width: 100px;
}
.join_h {
  color: #d9b451;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 19px;
}
.join_p {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  margin-top: 7px;
}
.overview_div2 .btn {
  font-weight: 400 !important;
}
@media (max-width: 480px) {
  .career_hero {
    height: 520px;
  }
  .career_parent {
    top: 52%;
    width: 60px;
  }
  .career_heading {
    font-size: 35px;
  }
  .navbar-light .navbar-toggler {
    padding: 0;
  }
}
.profileImagemain {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  box-shadow: 2px 1px 10px 0px #dfdfdf;
}
.signinp{
  background:none !important;
}

.heart_icon i {
  // font-size: 20px;
  color: #7e1e2d;
}
.paywitdh {
  margin: auto;
  width: 92%;
}
.paymentcard .container{
  max-width: 100% !important;
}
@media(min-width:820px){
  .paywitdh {
    width: 60%;
  }
}
.paymentcard {
  padding: 20px 0;
  border: 1px solid #80808070;
  // border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-bottom: 0;
  position: relative;
  margin-bottom: 30px;
}

.graybg{
  background: gray;
}
@media(max-width:767px){
  .graybg {
    min-height: 300px;
}
}
.payheader {
  color: #000;
  font-size: 24px;
  // margin-bottom: 30px;
  border-bottom: 1px solid #8080806e;
  padding-bottom: 15px;
  margin: 0;
}
.paymentlogo{
  height: 130px;
  margin-top: -10px;
}
.divphone .mobin{
  border: none !important;
  position: absolute;
  bottom: 1px;
  width: 86px !important;
  border-right:1px solid #8080806b !important;
}
.divphone .react-tel-input .form-control {
  background: transparent !important;
  border: none !important;
  height: 23px !important;
  font-size: 10px !important;
  color: #000 !important;
}
.divphone .typepoh{
  padding: 0px 25px 0px 92px;
}
.divphone .react-tel-input .selected-flag {
  padding: 0 !important;
}
.divphone .error {
  position: absolute;
  font-size: 10px;
  padding: 0px 10px;
  top: 55px;
  background: #fff;
  width: 95%;
}
.paymentRadio{
  margin-bottom: 5px;
}
.paymentRadio input{
  width: 16px !important;
  margin-right: 5px;
}
.paymentRadio label{
 margin: 0 !important;
 font-size: 15px ;
}
.savedCardR{
  width: 15px !important;
}
.radius_rev{
  border-radius: 5px !important;
}
// Table css
.table thead th {
  white-space: nowrap !important;
}
.namefull{
  white-space: nowrap  !important;
}
.tableHeaderFix .table thead th {
  white-space: nowrap !important;
}
.tableElipsis{
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}  

// View
.profileheddingclsAns{
  font-size: 14px;
    font-weight: 400;
    color: #5A5A5A !important;
    word-break: break-word;
    word-wrap: break-word;
    overflow: hidden;
    border-radius: 5px;
    background: #f2f2f2;
    padding: 6px 12px;
}

.ccnumfield{
  border: none !important;
}
h3.main_transcation {
  font-size: 20px !important;
}
.wswitch{
  position: relative;
  top: -10px;
  .bigToggle {
    width: 45px !important;
    height: 20px;
    margin-top: 0px !important;
}
}
.user_hours.active{
  background: #00800038 !important;
  height: 25px;
  .contract{
    color: #008000e6 !important;
  }
}
.user_hours.Approval{
  background: #00800038 !important;
  height: 25px;
  .contract{
    color: #008000e6 !important;
  }
}
.user_hours.Queued.for.Capture{
  span.contract {
    color: #333333d9 !important;
}
}
.user_hours.failed{
  background: #ff000057 !important;
  width: 170px;
  height: 25px;
  .contract{
    color: #ff0000e0 !important;
  }
}
.user_hours {
  min-width: 150px;
  height: 32px !important;
  padding: 0 10px;
  background: rgba(165, 180, 255, 0.2392156863);
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  cursor: pointer;
}
.user_hours .contract{
  white-space: nowrap !important;
  min-width: 135px !important;
  text-align: center;
}
.reqbtn{
  min-width: 125px;
  color: #fff !important;
    background-color: #7e1e2d !important;
    border-color: #7e1e2d !important;
    white-space: nowrap !important;
}
.sendbtn{
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  color: #fff !important;
  white-space: nowrap !important;
  min-width: 125px;
}

.side-profile .user_name {
  color: #fff;
  font-size: 16px;
  margin: 0 !important;
}
.side-profile p {
  color: #fff;
  font-size: 13px;

  line-height: 22px;
}
.side-profile {
  background: #791330;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.custom-padding {
  padding: 0 140px;
}
.new-table thead th , .new-table td{
  border-bottom: 1px solid #dee2e6 !important;
}
.new-table thead th {
  height: 60px;
  vertical-align: middle;
}
.invoice-action{
  width: 38px;
  height: 38px;
  background: #efefef;
  padding: 10px;
  border-radius: 6px;
}
.new-table .table{
 margin-bottom: 0;
}
.no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #dee2e6 !important;
  border-top: 0;
}
.no-data img {
  width: 134px;
}
.profuct-img {
  border-right: 1px dashed rgba(0, 0, 0, 0.431372549);
  background: rgba(124, 20, 49, 0.0509803922);
  padding: 21px !important;
  margin: 0 0 0 4px;
}
.product-price {
  display: flex;
  justify-content: space-between;
  margin: 9px 0 0 0;
  align-items: center;
}
.main-fields {
  padding: 21px;
  padding-left: 0;
}
.main-fields .profiledetailscls .inFix{
  font-weight: 400 !important; 
  font-size: 13px !important;
}
.address-fields-new {
  margin: 0 0 20px 0;
}
.main-fields .order_prices {
  font-size: 16px !important;
}
.profuct-img {
  border-right: 1px dashed rgba(0, 0, 0, 0.431372549);
  background: rgba(124, 20, 49, 0.0509803922);
  padding: 21px !important;
  margin: 0 0 0 4px;
  height: 100%;
}
.profuct-img  .order_images{
  height: 140px;
}
.product-t {
  font-size: 16px !important;
  text-transform: capitalize;
}
.price-font span {
  font-size: 22px;
  font-weight: 600 !important;
  color: #000000b8;
}
.order-summary-box {
  height: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: #f7f7f7;
  border: 1px solid #00000017;
}
.profile_img_side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 100%;
  border-right: 1px dashed #00000059;
}
.profile_img_side a {
  margin: 15px 0 0 0;
  width: 100%;
}
.profile-new .title-head {
  background: #811f2e12;
  padding: 18px;
  border-bottom: 1px solid #dbdbdb;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}
.profile-new  .product-detail-page p {
  margin: 0;
  font-size: 11px;
  font-weight: 400;
  color: grey;
  background: #7e1e2d0d;
  padding: 3px 5px;
  border-radius: 4px;
  line-height: 16px;
}
.main_profile_page label {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #5e5e5e;
  margin: 0 !important;
}
.profile-sect-m {
  position: relative;
}
.profile-sect-m label {
  position: absolute;
  right: 0;
  bottom: 0;
}
.role-display p {
  margin: 14px 0 0 0 !important;
  background: #811f2e;
  display: block;
  width: 100%;
  color: #fff;
  padding: 6px;
  border-radius: 6px;
}
.role-display {
  width: 100%;
  text-align: center;
}
.arrow-color {
  background: #842030;
  color: #fff;
  padding: 6px 10px;
  width: 40px;
  height: 36px;
  margin: 0 10px 0 0;
  border-radius: 5px;
}
.edit-profile label {
  font-size: 14px !important;
  color: #5e5e5e;
  margin: 0 0 9px 0 !important;
  font-weight: 600 !important;
}
@media only screen and (max-width:1440px){
  .custom-padding {
    padding: 0 80px;
}
}
@media only screen and (max-width:1399px){
  .custom-padding {
    padding: 0 40px;
}
}
@media only screen and (max-width:1199px){
  .main-fields {

    padding-left: 21px;
}
.profuct-img {
  border-right: 0;
  background: rgba(124, 20, 49, 0.0509803922);
  padding: 21px !important;
  margin: 0 4px 0 4px;
  height: 100%;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.431372549);
}
.order-summary-box {
  height: fit-content;

}
}
p.m-0.set-quentity {
  border: 1px solid grey;
  border-radius: 100%;
  padding: 0px 7px;
  color: grey;
  font-size: 11px;
}
p.m-0.quentity {
  font-size: 12px;
  border: 1px solid grey;
  padding: 0px 17px;
}
.add-cart {
  width: inherit !important;
  font-size: 14px !important;
  height: inherit !important;
  // padding: 5px 13px;
}

button.btn.btn-primary.set-wish i {
  font-size: 14px !important;
  // color: #fff !important;
}
.set-heart-icon {
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 10px;
  font-size: 19px;
  color: #7e1e2d;
}
h5.sales.mb-0 {
  font-size: 18px;
  font-weight: 600;
  COLOR: #000;
}
img.rounded-circle{
  border: 2px solid #fff;
  box-shadow: 2px 1px 10px 0px #dfdfdf;
}
.dropdown-menu.shadow.bg_hover.minWbtnShow.show {
  max-height: 200px;
  overflow: auto;
}
.paynowText{
  font-size: 25px;
  border-bottom: 1px solid #8080802b;
  padding-bottom: 24px;
}
.text-now {
  font-size: 13px;
  font-weight: 500;
  color: #363232;
}
.blogs_para_detail h2 {
  color: #000000;
  font-size: 23px;
  margin: 0px;
}


//--------new css-------//
p.accopuntt {
  color: #fff;
  font-size: 12px;
}

//Nikhil CSS 

//login 
.right_side{
  background: #000;
  border: 1px solid #d9b451;
}
button.btn.btn-primary.loginclass {
  color: #fff !important;
}
.centerLogin label{
  font-size: 12px;
  color: #b6b6b6;
}
.lgtext{
  font-size: 15px;
  font-weight: 500;
}
input.form-control.mb-0.bginput.changes {
  background-color: #000 !important;
  border: 1px solid #ffffff40 !important; 
  color: #ffff !important;
  font-size: 14px ;
}
input.form-control.bginput.chnages{
  background-color: #000 !important;
  border: 1px solid #ffffff40 !important;
  color: #fff !important;
  font-size: 14px;
}
.forget a{
  color: #b6b6b6;
  font-size: 14px;
}
.font-signup{
  font-size: 16px;
}
p.accopunt.text-center{
  color: #b6b6b6 !important;
}

//Login ends



// .d-flex.justify-content-between.align-items-center {
//   display: flex !important;
// }
// .headerSearch{
//   top: 0 !important;
// }


// //08-01-2025 CSS 

.info-wrapper h3{
  text-align: center;
  color: #222;
  font-size: 40px;
  font-weight: 700;
  margin: 0px 0px 40px 0px;
}
.info-wrapper h3::after {
  content: " ";
  background: #791330;
  width: 90px;
  height: 5px;
  display: block;
  margin: 10px auto;
  border-radius: 10px;
}
.info-content {
  background: #605e5e;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #efefef;
}
.info-content p{
  color: #fff ;
  font-size: 14px;
  line-height: 20px;
}
.LGOjhe {
  color: #fff;
}
.header-logo{
  border-radius: 5px !important;
  background-color: #fff;
  width: 90px;
}

//footer icons CSS
  .social-text li{
    font-size: 25px !important;
  }
//footer icons CSS ends

// p.accopunt.text-center{
//   color: #fff;
//   font-size: 12px;
//   font-weight: 400;
//   line-height: normal;
//   font-family: "Poppins";
// }

// .signup-btn{
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: 120px;
// }
// .dark-btn:active{
//   color: #fff !important;
// }
// .dark-btn:focus{
//   box-shadow: none !important;
// }

// .signup-btn img{
//   margin-left: 0 !important;
// }


// //08-01-2025 CSS ends


// @media screen and (max-width:991px) {
//   .dark-header .navbar-light .navbar-nav .nav-link{
//     color: #000 !important;
//     justify-content: end;
//   }
//   .login-btn{
//     color: #000 !important;
//   }
//   .signup-btn{
//     max-width: 110px;
//     height: 35px;
//   }
//   .navbar .container-fluid{
//     justify-content: end !important;
//     gap: 5px;
//   }
//   .colit{
//     gap: 15px;
//     align-items: end !important;
//   }
//   .colit a{
//     margin-right: 10px;
//     margin-left: 0;
//   }
//   .dark-header .navbar-light .navbar-nav .dropdown-menu.box-shadow-unset.show li a {
//     text-align: end;
//   }
// }



// @media (max-width: 576px) {
//   .header-top a {
//     font-size: 9px;
//   }
// }

// @media screen and (max-width:425px) {
//   .marginbm60 .btn{
//     max-width: 130px;
//     font-size: 11px;
//     height: 45px;
//   }
 
//   .headerSearch .Searchbar{
//     padding: 6px 0px 6px 5px;
//   }
//   h2{
//     font-size: 20px;
//   }
//   .mobile-show input[type=search]{
//     max-width: 120px;
//   }
//   .signup-btn{
//     padding: 7px 7px;
//     height: 35px;
//     font-size: 11px;
//     font-size: 12px;
//     max-width: 95px;
//   }
//   .login-btn{
//     font-size: 13px;
//   }
//   .dark-header .navbar-light .navbar-nav .dropdown-menu.box-shadow-unset.show li a {
//     font-size: 0.7rem;
//   }
// }


