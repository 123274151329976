@import '../../scss/mixins';

.main-wrapper {
  background-color: #fff;

  main.main {
    width: calc(100% - 280px);
    padding-left: 0px;
    padding-right: 0px;
  }
}

.editLogo {
  display: inline-block;
  position: relative;
  cursor: pointer;

  >i {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.logocls {
  width: 100%;
  max-width: 185px !important;
}

.dash-title {
  position: relative;
  background-color: #f6f8fb;
  padding: 11px 19px;
  font-size: 28px;
  line-height: normal;
  border-radius: 6px;
  margin-bottom: 30px;

  .total {
    font-size: 14px;
    float: right;
    font-weight: 600;
    margin-top: 10px;
  }
}

.main-sidebar {
  position: relative;
  background-color: #fff;
  max-width: 250px;
  min-width: 250px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.countcart{
  position: absolute;
  top: -12px;
  font-size: 8px;
  background: #fff;
  color: #000;
  border-radius: 100%;
  left: 12px;
  width: 18px;
  height: 18px;
  line-height: 9px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerCart{
  font-size: 20px;
}
.bg-nonebt{
  background: none;
}
@media (max-width: 767px) {
  .main-wrapper main.main {
    width: 100%;
  }
}

@media (max-width: 467px) {
  .form-row.uploaded-image-row>div {
    max-width: 50%;

    .uploaded-image img {
      height: 100px;
    }
  }
}
.wishimg{
  height: 32px;
  // border-radius: 5px;
  cursor: pointer;
}
.wiH{
  font-size: 25px;
}

//Nikhil CSS starts

@media screen and (max-width:425px) {
  .wiH{
    font-size: 20px;
  }
}