header {
    background-color: #050505;
    padding: 0 0 10px 0;
}

@media(max-width:480px){
    .maintgl{
        width: 42px;
        font-size: 18px;
    }
}
@media(max-width:991px){
    .maincpls{
        background: rgb(111 33 46);
    padding: 20px;
    width: inherit;
    z-index: 9;
    border-radius: 0px 0px 10px 10px;
    }
    .headerbMob{
        position: absolute;
        right: 0;
        width: 100%;
    }
}
//Nikhil CSS starts

@media screen and (max-width:425px) {
    .maintgl{
        width: 30px;
        font-size: 14px;
    }
    
}